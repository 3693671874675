import { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import LoginScreen from '@/components/login/LoginScreen';
import SocialMetaTags from '@/components/SocialMetaTags';
import { ACCESS_TOKEN_KEY } from '@/constants/common';
import { postMessageToWebView } from '@/utils/helper';
interface Props {
  title: string;
}
const LoginPage = ({
  title
}: Props) => {
  const {
    t
  } = useTranslation('common');
  useEffect(() => {
    if ('ReactNativeWebView' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      postMessageToWebView(window, {
        code: 'ERR_001',
        message: 'logout'
      });
    }
  }, []);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="login.tsx">
        <meta name="title" content={title} data-sentry-element="meta" data-sentry-source-file="login.tsx" />
        <meta name="description" content={t('app_description')} data-sentry-element="meta" data-sentry-source-file="login.tsx" />
      </Head>
      <SocialMetaTags title={title} description={t('app_description')} data-sentry-element="SocialMetaTags" data-sentry-source-file="login.tsx" />
      <LoginScreen data-sentry-element="LoginScreen" data-sentry-source-file="login.tsx" />
    </>;
};
export async function getServerSideProps({
  req: {
    cookies
  }
}: GetServerSidePropsContext) {
  const accessToken = cookies[ACCESS_TOKEN_KEY];
  if (accessToken != null) {
    return {
      redirect: {
        destination: '/',
        permanent: false
      }
    };
  }
  return {
    props: {
      title: 'DeHaat Kisan App: Login page'
    }
  };
}
export default LoginPage;